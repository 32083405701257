/**
 * Setar novo token e datas para cada chamada da API
 * OBS: Para algumas APIs, s� � setado o token, ent�o se o par�metro apiName for vazio, ele s� seta o token e n�o muda as datas
 *
 * @param builder Builder
 * @param actionType Nome do Fetch
 * @param apiName nome do par�metro que vem da API. Se for vazio ele n�o muda as datas
 */
export const fetchFulfilledStateBuilder = (
  builder: any,
  actionType: any,
  apiName: string,
) => {
  builder.addCase(actionType.fulfilled, (state: any, action: any) => {
    if(apiName !== ''){ // S� muda as datas se passar como par�metro o apiName
      state.recalcular = false;
      if (
        action.payload[apiName]?.obj_datas &&
        action.payload[apiName]?.obj_datas.data_fim !== state.dateEnd
      ) {
        state.dateEnd = action.payload[apiName]?.obj_datas.data_fim.split('/').join('');
      }
      if (
        action.payload[apiName]?.obj_datas &&
        action.payload[apiName]?.obj_datas.data_ini !== state.dateStart
      ) {
        state.dateStart = action.payload[apiName]?.obj_datas.data_ini.split('/').join('');
      }
    }

    if (action.payload.access_token && action.payload.expires_in) {
      state.token.value = action.payload.access_token;
      state.token.expiration = action.payload.expires_in * 1000;

      window.localStorage.setItem(
        `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
        JSON.stringify({ ...state.token }),
      );
    }
  });
};

/**
 * Caso do token expirado nas chamadas da API
 * @param builder Builder
 * @param actionType Nome do Fetch
 */
export const fetchRejectedStateBuilder = (
  builder: any,
  actionType: any,
) => {
  builder.addCase(actionType.rejected, (state: any, action: any) => {
    const payload = action.payload as { msg: string; codigo: string };
    if (payload && payload?.codigo === '5c32fa') state.unauthorized = true;
    if (payload && payload?.msg === 'Expired token') {
      window.localStorage.removeItem(`token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`);
      state.token.value = '';
    }
  });
};