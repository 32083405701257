export const fontFamily = 'Lato';

export const backgroundColor = '#F8F8F8';
export const fontColorMenu = '#F8F8F8';
export const titleFontColor = '#00684E';
export const menuIconsColor = 'white';
export const iconsColor = '#00684E';
export const fontColor = '#555555';
export const dropSelectorColor = '#3E4755';
export const disabledColor = '#00000061';
export const tabGradientColor = '#F8F8F8' //Adicionar cor ao Side Menu dependendo do cliente

export const loginColor = 'white';
export const logininputColor = 'white';
export const loginErrorColor = 'red';
export const loginTermosDeUsoColor = '#E7E7E7';
export const loginBoxBGColor = '#222933';
export const loginBGColor = '#222933';
export const loginButtonBgc = '#33CC9D';
export const loginButtonColor = 'black';
export const loginBorder = 'none';

export const topMenuBoxShadow =
  '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)';

export const barChartColor = '#02D181';
export const lineChartColors = [
  '#02D181',
  '#22B8CF',
  '#7950F2',
  '#BE4BDB',
  '#DB4B90',
  '#F59F00',
  '#FD7E14',
  '#4EAB2D',
  '#2F7716',
  '#721D87',
  '#450775',
];

export const chartColors = [
  '#02D181',
  '#22B8CF',
  '#7950F2',
  '#BE4BDB',
  '#DB4B90',
  '#F59F00',
  '#FD7E14',
  '#4EAB2D',
  '#2F7716',
  '#721D87',
  '#450775',
];

export const chartLighterColors = [
  '#dff4ec',
  '#e2f2f4',
  '#ebe7f8',
  '#f2e7f5',
  '#f5e7ee',
  '#f8efdf',
  '#f9ece1',
  '#e4ebe1',
  '#ebe2ed',
  '#ddcce1',
];

export const chartLightColors = [
  '#c6f0e0',
  '#cbdfea',
  '#ded6f6',
  '#ecd5f2',
  '#f2d5e3',
  '#f7e6c6',
  '#f9dfca',
  '#cfdeca',
  '#ddcce1',
  '#d4c7dd',
];

export const defaultLegendColor = '#D9D9D9';

export const fontSizeTitle = '24px';
export const xsFontSize = '10px';
export const smFontSize = '12px';
export const mdFontSize = '16px';
export const lgFontSize = '20px';
export const xlFontSize = '28px';
export const lineHeight125 = '125%';

export const lineColor = '#bebebe';
export const bgcMain = '#171C24';
export const bgcMenuDivider = '#171C24';
export const bgcSecondary = '#FFFFFF';
export const borderRightMenu = '1px solid #171C24';

export const orange = '#EF770C';
export const red = '#EF0C0C';
export const primaryColor = '#193493';
export const lightBlue = '#23B0DC';
export const positiveGreen = '#26A69A';
export const negativeRed = '#e73c3c';

export const tableCellColor = '#545F6E';
export const tableCellDarkerColor = '#3E4755';

export const borderRadius = '10px';

export const xsPadding = '8px';
export const smPadding = '16px';
export const mdPadding = '24px';
export const lgPadding = '32px';
export const xlPadding = '40px';

export const xsMargin = '8px';
export const smMargin = '16px';
export const mdMargin = '24px';
export const lgMargin = '32px';
export const xlMargin = '40px';

export const mobileS = '320px';
export const mobileM = '375px';
export const mobileL = '425px';
export const mobileXL = '490px';
export const tablet = '768px';
export const laptop = '1024px';
export const laptopL = '1440px';
export const desktop = '2560px';
